

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/engager-avocat-droit-travail-congediement-illegal-blessure-professionnelle.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost33 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Avocat en droit du travail pour congédiement illégal et blessure professionnelle au Québec - Soumissions Avocat"
        description="On vous a mis à la porte sans raison valable? Vous avez été congédié pour un motif discriminatoire ou suite à une blessure professionnelle? Vous avez des droits et le temps pour les faire valoir avec un avocat commence dès maintenant avec Soumissions Avocat!"
        image={LeadImage}>
        <h1>Avocat en droit du travail pour congédiement illégal et blessure professionnelle au Québec</h1>

					
					
						<p>Vos nombreuses années de bons et loyaux services ont été récompensées en vous faisant montrer la porte? Votre employeur vous congédie pour un motif discriminatoire qui porte atteinte à vos droits? Vous n’avez pas à sortir du building avec votre boîte en carton bredouille. La loi prévoit des droits pour tous les salariés de la province en termes de congédiement illégal, et un avocat en droit du travail se fera un plaisir de vous aider tout au cours de cette démarche!</p>
<p><StaticImage alt="Un avocat au Québec pour congédiement illégal et blessure professionnelle" src="../images/engager-avocat-droit-travail-congediement-illegal-blessure-professionnelle.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Un avocat pourra même vous assister si vous avez été <strong>victime d’une blessure ou d’une lésion professionnelle</strong> dans votre milieu de travail! Les recours à la CNESST exigent une spécialisation juridique particulière et Soumissions Avocat se fera une mission de trouver le meilleur expert au Québec!</p>
<h2>À quoi sert l’expertise d’un avocat en droit du travail? Vos droits en tant que salarié!</h2>
<p>Le milieu de travail est un environnement régit non seulement pas le Code civil du Québec, mais également par de nombreuses lois particulières dont la <strong>Loi sur les normes du travail</strong>. C’est cette dernière qui offre le plus de protections aux salariés québécois contre les manœuvres abusives d’un employeur, dont les congédiements illégaux.</p>
<p>À titre informatif, la Loi sur les normes du travail constitue le seuil minimal en termes de conditions de travail qu’un employeur est obligé de respecter. C’est le barème en deçà duquel aucun employeur ne peut descendre en termes de salaire minimum, d’heures supplémentaires, de vacances, de congés parentaux et autres avantages auxquels tout travailleur a droit.</p>
<p>Cette même loi prévoit également vos obligations en tant qu’employé que vous devez absolument respecter. On pense notamment à votre obligation de loyauté, votre devoir de respecter les directives de vos supérieurs, d’effectuer votre travail de la façon la plus sécuritaire qui soit et de fournir la prestation de travail vous-mêmes.</p>
<p><strong>Ce qui justifie l’expertise de l’avocat en droit du travail, c’est sa capacité à déceler les manœuvres d’employeurs tentant d’esquiver ces droits : vos droits!</strong></p>
<p>Des recours sont prévus dans la loi pour obtenir un dédommagement suite à un congédiement injustifié et il n’en tient qu’à vous de vous en prévaloir en engageant un avocat en droit du travail.</p>
<h2>Comment intenter un recours pour un congédiement sans cause juste et suffisante?</h2>
<p>Contrairement à la croyance populaire qui court, au Québec, un employeur est en droit de mettre fin au contrat de travail conclu avec son employé. Cependant, ce congédiement ne doit pas être fait en raison d’une pratique interdite au sens de la Loi sur les normes du travail et encore moins en se basant sur des pratiques discriminatoires.</p>
<p><StaticImage alt="Le congédiement sans cause juste et suffisante" src="../images/motifs-illegaux-congediement-grossesse-discrimination-recours-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>La loi qualifie ces motifs de congédiement comme des « pratiques interdites ». Parmi elles, on compte le congédiement suite à la demande du congé de maternité, la sanction pour avoir réclamé l’indemnité prévue lors de jours fériés ainsi que les mesures disciplinaires prises suite au refus d’effectuer des heures supplémentaires.</p>
<p>Peu importe le nombre d’années travaillées pour votre employeur, il est toujours illégal de mettre quelqu’un à pied pour l’une de ces raisons et vous aurez toujours un recours contre un tel employeur.</p>
<p>Toutefois, la loi prévoir également des recours pour les salariés justifiant <strong>plus de 2 ans de service continu pour le même employeur</strong>. À quoi ressemble cette protection? Après ce délai, un employeur ne pourra plus vous congédier sans une cause juste et suffisante!</p>
<p><strong>Comment déterminer si un employé a été congédié pour un motif juste et suffisant?</strong> Malheureusement pour vous, cela s’appréciera dans les faits particuliers à votre situation. Les circonstances seront analysées en détail afin de déterminer s’il était bien justifié de vous monter la porte.</p>
<p>Heureusement pour vous, <strong>le fardeau de cette preuve reposera sur les épaules de votre employeur!</strong> C’est lui qui devra montrer que votre comportement ou vos performances méritaient bien votre congédiement.</p>
<p>Advenant que le congédiement était injustifié, vous serez en droit de réclamer votre réintégration dans votre milieu de travail, le paiement d’une indemnité et, en cas de mesures disciplinaires prises contre vous, la cessation de ces représailles.</p>
<p>Notez toutefois que tous les mécanismes mentionnés ci-haut s’appliquent lorsque vous êtes dans un contrat de travail individuel avec votre employeur et que, autrement dit, vos relations de travail ne sont régies par aucune convention collective.</p>
<h2>Vous êtes syndiqué? Voici à quoi vous en tenir!</h2>
<p>Qui dit syndicat dit convention collective et qui dit convention collective signifie prises de bec entre l’employeur et les dirigeants du syndicat. De nombreuses prises de bec tournent justement autour des congédiements d’employés syndiqués! Contrairement aux autres employés, ceux qui font le choix de se syndiquer deviennent régis, dans leurs relations de travail, par leur convention collective.</p>
<p>Ainsi, lorsqu’un de ces employés se fait montrer la porte ou fait l’objet de mesures disciplinaires, il n’a d’autres choix que de <strong>se tourner vers ses représentants syndicaux </strong>pour intenter un grief contre son employeur. Le grief devient donc le recours par défaut pour tout travailleur syndiqué et c’est le syndicat qui se charge du recours contre l’employeur.</p>
<p><strong>Qu’arrive-t-il si le syndicat refuse de supporter votre grief? </strong>Vous avez le droit de vous tourner vous-mêmes vers le Tribunal administratif du travail afin de faire valoir vos droits.</p>
<p>Votre syndicat se doit d’avoir de très bonnes raisons de ne pas prendre votre grief en charge, puisqu’il est justement en place pour faire valoir vos droits face aux agissements de votre employeur.</p>
<p><strong>En cas de doute, faites appel à un avocat en droit du travail! Ces juristes sont formés pour interpréter des conventions collectives et sont en mesure de vous représenter en cas de grief rejeté par vos représentants syndicaux!</strong></p>
<h2>Quels sont les motifs de congédiement illégaux au Québec?</h2>
<p>Vous avez refusé d’effectuer un certain type de travail ou on vous a mis à la porte suite à une simple demande quant au respect de vos droits? Cela n’a pas lieu d’être! De nombreuses lois visent à protéger le salarié contre des employeurs abusifs et l’histoire fait ressortir de nombreuses histoires victorieuses d’employés contre de tels supérieurs. Voici les principaux motifs de congédiement strictement prohibés.</p>
<p><StaticImage alt="Congédié pour un motif discriminatoire" src="../images/comment-contester-congediement-normes-travail-cnesst-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Le refus de pratiquer un travail dangereux :</strong> L’employé a un droit fondamental protégé entre autres par le Code civil du Québec à un environnement de travail sain et sécuritaire. C’est à l’employeur de fournir un tel espace de travail à ses employés et, lorsque ces derniers jugent que les standards de sécurité ne sont pas respectés, ils sont en droit de refuser de fournir leur prestation de travail.</p>
<p>Le corolaire de ce droit est également l’interdiction pour un employeur de sanctionner quelconque employé qui invoque un tel refus, du moment que celui-ci est justifié.</p>
<p><strong>Congédiement suite à une blessure professionnelle :</strong> En vertu de la Loi sur les accidents de travail et les maladies professionnelles, un employé a le droit d’être dédommagé part la CNESST suite à un accident de travail, une maladie professionnelle ou une blessure professionnelle.</p>
<p>Cette loi prévoit également un droit à un congé pour permettre à un employé de se remettre de ces blessures, et il est strictement interdit pour un employeur de profiter de cette absence pour remplacer son employé ou pour le mettre à pied.</p>
<p><strong>L’exercice d’un droit prévu dans la Loi sur les normes du travail :</strong> On parle ici des droits minimaux tels que le droit aux vacances en fonction du nombre de temps passé chez l’employeur, au droit à un certain nombre de congés de maladie, à un préavis de congé raisonnable lors d’une mise à pied, etc.</p>
<p><strong>Témoin au tribunal ou assigné comme juré :</strong> Certains motifs peuvent excuser votre absence du travail sans que votre employeur ne puisse dire quoi que ce soit. Lorsque les plus hautes instances de la justice vous interpellent et vous assignent à comparaître ou à agir comme juré, votre employeur ne peut faire usage de ce prétexte pour vous congédier.</p>
<p><strong>Congé de maternité :</strong> Fait bien connu de tous, il est absolument interdit de vous congédier en raison de l’annonce de votre grossesse, de votre départ pour un congé de maternité ou pour tout autre motif relié.</p>
<p>Des employeurs usent parfois de stratagèmes rusés pour parvenir à congédier un employé sans avoir l’air d’enfreindre l’un des motifs prohibés ci-haut. <strong>On qualifie de tels agissements de congédiements déguisés et les avocats en droit du travail sont loin de se faire duper par de telles mascarades!  </strong></p>
<h2>Les compensations auxquelles vous pouvez vous attendre suite au congédiement illégal</h2>
<p>Il revient toujours au tribunal de fixer la compensation à laquelle vous avez droit (ou à l’arbitre de grief dans un milieu syndiqué) et celui-ci jouit d’un large pouvoir discrétionnaire pour déterminer la réparation appropriée.</p>
<p>Il va de soi que le contexte spécifique à chaque milieu de travail guidera le juge vers sa décision, en ce sens qu’il peut être plus difficile d’ordonner la réintégration dans certains milieux que d’autres. L’éventail de choix ne manque pas d’options toutefois :</p>
<p><strong>La réintégration au milieu de travail :</strong> Lorsque le tribunal l’estime approprié, le juge est en droit d’ordonner la réintégration de l’employé dans son milieu de travail. Il va de soi qu’une dispute envenimée avec l’employeur ou des relations toxiques avec les autres employés feront pencher la balance vers une autre solution.</p>
<p>Le contrat de travail implique également un lien de confiance reconnu par le droit. Lorsque ce lien de confiance a été rompu de part et d’autre, la réintégration ne devient plus possible.</p>
<p><strong>Remboursement du salaire et paiement d’une indemnité :</strong> Entre le temps de votre congédiement illégal et votre journée devant le tribunal, un certain délai se sera écoulé. Vous aurez toutefois le droit de réclamer le salaire perdu à votre employeur pendant cette période, en plus d’une indemnité supplémentaire si le juge la considère appropriée.</p>
<p><strong>La cessation des mesures prises contre vous :</strong> Lorsque le congédiement est la suite logique de pratiques discriminatoires ou disciplinaires injustifiées, la réparation appropriée pourra être la cessation de ces mesures à votre égard. Cela implique évidemment une réintégration dans le milieu de travail.</p>
<p>Pourquoi prendre un recours en vertu du Code civil? Il peut être avantageux de prendre un recours en vertu du Code civil pour plusieurs raisons. Ce sont surtout les employés n’ayant pas encore accumulé 2 ans de service continu qui leur permettrait d’utiliser la Loi sur les normes du travail qui choisiront cette option!</p>
<p>Comme le Code civil protège lui aussi contre les congédiements injustifiés, il est possible de vous prendre un avocat pour aller plaider votre cause devant la cour tout en étant représenté. De plus, le <strong>Code civil prévoit un délai de 3 ans</strong> pour entreprendre un recours, alors que la <strong>Loi sur les normes du travail</strong> ne vous octroie que <strong>45 jours</strong> pour agir.</p>
<h2>À quoi ressemble un congédiement déguisé?</h2>
<p>Le congédiement déguisé est une pratique trop commune. Elle fait l’objet de nombreux débats au sein du droit et a eu raison de beaucoup trop d’emplois. Comme son nom le laisse sous-entendre, le congédiement déguisé consiste à modifier radicalement les conditions de travail de son employé afin que celui-ci les refuse et quitte son emploi.</p>
<p><StaticImage alt="À quoi ressemble un congédiement déguisé?" src="../images/lois-travail-quebec-congediement-deguise-poursuite-employeur.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Un test en deux étapes a été mis en place pour déterminer la présence d’un congédiement déguisé. Il doit tout d’abord y avoir une grave <strong>violation du contrat travail</strong> qui démontre effectivement un désir de l’employeur de ne plus être lié par ce même contrat.</p>
<p>Par la suite, la seconde condition consiste à se demander si une personne raisonnable placée dans la même situation que l’employé en question conclurait à une <strong>modification substantielle des conditions de travail</strong>.</p>
<p>Faites bien attention! Pour être en mesure de vous prévaloir d’un recours pour congédiement déguisé, il est essentiel de refuser les modifications proposées par votre employeur. Cependant, un employeur pourrait décider de <strong>modifier progressivement les conditions</strong> de son employé afin de le pousser lentement mais surement vers la porte de sortie.</p>
<p>Le droit reconnaît maintenant qu’une telle pratique pourra constituer un congédiement déguisé au vu et su des circonstances. Il ne faut toutefois pas monter sur ses grands chevaux trop rapidement!</p>
<p>Ce ne sont pas tous les commentaires, reproches ou modifications de vos conditions apportées par votre employeur qui mettront la table pour un congédiement déguisé. Le monde du travail est enclin au changement et la ligne se trace au niveau de la bonne foi de l’employeur!</p>
<h2>Victime d’une blessure professionnelle, qui vous indemnisera?</h2>
<p>Une autre raison pour laquelle engager un avocat pourrait vous être fort utile est dans le cas d’une blessure professionnelle! Dans de telles circonstances, c’est la Loi sur les accidents de travail et les maladies professionnelles (LATMP) qui encadrera la marche à suivre pour vous obtenir une compensation. Mais qui paie pour une telle blessure?</p>
<p><strong>Les joies et les peines d’un régime de responsabilité sans faute!</strong> Les employeurs paient tous des cotisations à la CNESST afin que ce soit la commission qui paie les employés lors d’un accident de travail! Il s’agit d’ailleurs d’un régime de responsabilité qualifié de « sans faute ». Cela signifie que dans le cas d’un accident de travail ou d’une blessure professionnelle, il est impossible de poursuivre votre propre employeur.</p>
<p><strong>Il vous faut absolument vous tourner vers la CNESST afin d’obtenir une indemnisation, sauf exceptions extrêmement pointues. </strong></p>
<p>Heureusement pour vous, la LATMP prévoit qu’une blessure survenue sur les lieux de travail est présumée être une lésion professionnelle! Si tel est votre cas, ce sera à votre employeur de démontrer que vous ne répondez pas aux critères de la loi, ce qui lui attribue donc le fardeau de réfuter vos allégations.</p>
<p><strong>Pour vous assurer d’obtenir une juste compensation suite à un accident de travail, une blessure professionnelle ou même une maladie issue de votre métier, faites appel à un avocat spécialisé dans le domaine pour recevoir conseils!</strong></p>
<h2>Toutes vos questions fréquentes sur vos droits au travail!</h2>
<p>S’il y a bien un aspect de la vie que chaque citoyen partage, c’est le fait de travailler. Il n’est donc pas surprenant que de nombreuses questions surgissent en lien avec ce milieu au fil du temps. Connaître ses droits donne de bien meilleures chances de les faire valoir avec succès n’est-ce pas!</p>
<p><StaticImage alt="Questions fréquentes sur les droits en milieu de travail" src="../images/questions-frequentes-droit-travail-congedie-sans-raison-avocat-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Suis-je lié à une clause de non-concurrence pour toujours?
</strong>
Absolument pas! Une clause de concurrence survient surtout dans des milieux de travail de travail compétitifs et contingentés et vise à prévenir les changements de camp qui pourraient être néfastes pour l’employeur.</p>
<p>Toutefois, ces clauses doivent se limiter à un type de travail spécifique, un territoire donné et à une durée déterminée. Une telle clause doit être le moins restrictive possible et doit se limiter à la stricte protection des intérêts légitimes de l’employeur.</p>
<p>Si un seul de ces aspects de cette clause est jugé abusif, un juge la fera tomber en totalité. C’est donc à vérifier avec un avocat!</p>
<p><strong>Je n’ai jamais signé de contrat de travail par écrit, suis-je quand même protégé?
</strong>
Il n’est aucunement nécessaire qu’un contrat de travail soit mis par écrit. Une entente verbale, comme c’est le cas pour la majorité des salariés au Québec, est suffisante pour avoir un contrat de travail valide.</p>
<p>Dès cette entente en place, tous les droits inhérents au contrat de travail s’ouvrent à vous, notamment les recours contre un congédiement mentionnés ci-haut.</p>
<p><strong>Est-ce que mon employeur peut me sanctionner parce que je souhaite me syndiquer?
</strong>
Sous aucun prétexte! Le droit à la libre association est prévu dans la Charte canadienne des droits et libertés et prévoit que tout salarié a le droit de faire partie et de mettre en place un syndicat.</p>
<p>De plus le Code du travail prévoit les lois applicables au processus de syndicalisation et protège lui aussi ce droit jugé fondamental dans une société libre et démocratique.</p>
<p><strong>Jusqu’où s’étend mon obligation de loyauté envers mon employeur?</strong></p>
<p>Même en l’absence d’une convention spécifique ou d’une clause de non-concurrence, vous êtes tenu par la loi d’être loyal envers votre employeur. Même avant de commencer officiellement à travailler pour lui et en dehors de vos heures de travail, cette obligation perdure.</p>
<p>Ainsi, les commentaires dégradants, les insultes publiques sur les réseaux sociaux et la communication d’informations confidentielles sont tous des transgressions à cette obligation qui peut donner ouverture à un congédiement.</p>
<h2>Obtenez réparation sans plus tarder en engageant un avocat en droit du travail au Québec!</h2>
<p>Vous passez votre vie au travail et vous avez le droit de vous faire respecter. La façon numéro un faire valoir vos droits, c’est d’engager un avocat compétent en droit du travail suite à un congédiement illégal ou une blessure professionnelle! Des recours existent pour vous donner les indemnités auxquelles vous avez droit.</p>
<p><strong>Comment concrétiser cette démarche et obtenir gain de cause? Vous laissez Soumissions Avocat vous assister dans votre démarche en vous mettant en contact avec les meilleurs avocats en droit du travail au Québec!</strong></p>
<p><strong>Tout ce que vous avez à faire, c’est de remplir le formulaire de demande et le tour est joué! En plus, cette mise en contact est gratuite et ne vous engage à rien!</strong></p>
    </SeoPage>
)
export default BlogPost33
  